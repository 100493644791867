import { lazy, Suspense, useEffect, useState } from "react";
import { configureChains, createClient, useAccount, WagmiConfig } from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
/// Components
import Index from "./jsx";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action

import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

  import { publicProvider } from "@wagmi/core/providers/public";
  import {
    coinbaseWallet,
    injectedWallet,
    metaMaskWallet,
    rainbowWallet,
    trustWallet,
    walletConnectWallet,
  } from "@rainbow-me/rainbowkit/wallets";
  import {
    connectorsForWallets,
    darkTheme,
    getDefaultWallets,
    RainbowKitProvider,
  } from "@rainbow-me/rainbowkit";

  import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import {
  userAllDetailsAction,
  userExistAction,
  walletAddressAction,
} from "./store/actions/AuthActions";
import { isUserExist } from "./helpers/getWeb3";
import KycStatus from "./jsx/pages/KycStatus";
import { getUserRandomId } from "./helpers/api";


const SignUp = lazy(() => import("./jsx/pages/Registration"));

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

var merkle = {
  id: 1909,
  name: "Merkle Scan",
  network: "Merkle Scan",
  nativeCurrency: {
    decimals: 18,
    name: "MRK",
    symbol: "MRK",
  },
  rpcUrls: {
    default: { http: ["https://marklechain-rpc.merklescan.com/"] },
  },
  blockExplorers: {
    etherscan: { name: "MerkleScan", url: "https://merklescan.com/" },
    default: { name: "MerkleScan", url: "https://merklescan.com/" },
  },
  contracts: {
    multicall3: {
      address: "0xDe749FC0804F010b10BB86A8F87EA27059354B9F",
      blockCreated: 703024,
    },
  },
  testnet: false,
};

const { chains, provider, webSocketProvider } = configureChains(
  [merkle],
  [publicProvider()],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `${chain.rpcUrls.default.http[0]}`,
      }),
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      coinbaseWallet({ chains, appName: "My RainbowKit App" }),
      walletConnectWallet({ chains }),
    ],
  },
]);

// const { connectors } = getDefaultWallets({
//   appName: "My RainbowKit App",
//   chains,
// });

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  // webSocketProvider,
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const [kycStatus, setKycStatus] = useState(0);
  const dispatch = useDispatch();
  const route = useLocation();
  const { userExist, userRefresh, userAllDetails } = useSelector((action) => {
    return action.auth;
  });
  const { address, isDisconnected } = useAccount();

  function getViewAddressFromUrl() {
    const url = route?.search;
  
    const urladdress = url?.substring(url?.indexOf("=") + 1);
     console.log(urladdress,"geturl")
    if (urladdress && route?.pathname !== "/register") {
      dispatch(walletAddressAction(urladdress));
      isUserExist(urladdress).then((res) => {
        // console.log(res ,"return")
        dispatch(userExistAction(res));
      });
      getUserRandomId(urladdress).then((res) => {
        console.log(res?.data?.length,"resurldeta");
        if (res?.data?.length > 0) {
          dispatch(userAllDetailsAction(res));
          setKycStatus(Number(res?.data[0]?.kysstatus));
        } 
      })
      return true;
    } else {
      // dispatch(userExistAction(false));
      return false;
    }
  }

  useEffect(() => {
    // if(!isDisconnected){
    if (!getViewAddressFromUrl()) {
      dispatch(walletAddressAction(address));
      if (address && userAllDetails?.length > 0) {
        isUserExist(address).then((res) => {
          dispatch(userExistAction(res));
        });
        
      } else {
        dispatch(userExistAction(false));
      }
    }
  }, [address, userRefresh]);

  useEffect(() => {
    const { search, pathname } = props.router.location;
    if ((!userExist || !address) && pathname !== "/register" && !search) {
      props.router.navigate("/login");
    }
    // console.log(address, "mnp address");
    if (address) {
      getUserRandomId(address).then((res) => {
     
        if (res?.data?.length > 0) {
          dispatch(userAllDetailsAction(res));
          setKycStatus(Number(res?.data[0]?.kysstatus));
          isUserExist(address).then((res) => {
            dispatch(userExistAction(res));
          });
        } else {
          // dispatch(userAllDetailsAction([]));
           setKycStatus(0);
          // dispatch(userExistAction(false));
          if (
            (!userExist || !address) &&
            pathname !== "/register" &&
            !search
          ) {
            props.router.navigate("/login");
          }
        }
      });
    } else {
      // dispatch(userAllDetailsAction([]));
       setKycStatus(0);
      // dispatch(userExistAction(false));
      if ((!userExist || !address) && pathname !== "/register" && !search) {
        props.router.navigate("/login");
      }
    }
  }, [address, userExist, userRefresh]);
   
  if (props.isAuthenticated) {
    // alert("hmm working")
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
              chains={chains}
              modalSize="compact"
              theme={darkTheme({
                ...darkTheme.accentColors.green,
              })}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    (!userExist || !address) && kycStatus === 0 ? (
                      <Navigate to="/login" />
                    ) : kycStatus === 1 ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Navigate to="/kyc" />
                    )
                  }
                />
                <Route
                  path="/login"
                  element={
                    (!userExist || !address) && kycStatus === 0 ? (
                      <Login />
                    ) : kycStatus === 1 ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Navigate to="/kyc" />
                    )
                  }
                />
                <Route
                  path="/kyc"
                  element={
                    kycStatus === 0 ? (
                      <KycStatus />
                    ) : (
                      <Navigate to="/dashboard" />
                    )
                  }
                />
                <Route path="/register" element={<SignUp />} />
              </Routes>
              {userExist && address ? <Index /> : null}
            </RainbowKitProvider>
          </WagmiConfig>
        </Suspense>
      </div>
    );
  }
}

export default withRouter(App);
