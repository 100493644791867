import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer out-footer">
      <div className="copyright">
        <p>
          {" "}
          © Copyright {d.getFullYear()}{" "}
          <a href="#" target="_blank" rel="noreferrer">
            BigtyCoon.io
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
