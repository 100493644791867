import { LuBoxes } from "react-icons/lu";
import { BiMoneyWithdraw, BiStreetView } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
 import { VscSymbolConstant } from "react-icons/vsc";
import { GiGrowth } from "react-icons/gi";
import { GrGroup } from "react-icons/gr";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaStaylinked,FaTicketAlt  } from "react-icons/fa";

export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    to: "/dashboard",
    iconStyle: <i className="material-icons">grid_view</i>,
    // content: [

    //     {
    //         title: 'Dashboard Light',
    //         to: 'dashboard',
    //     },
    //     {
    //         title: 'Dashboard Dark',
    //         to: 'dashboard-dark',

    //     },
    //     {
    //         title: 'Dashboard-2',
    //         to: 'index-1',

    //     },
    //     {
    //         title: 'Dashboard-3',
    //         to: 'index-3',

    //     },
    //     {
    //         title: 'Dashboard-4',
    //         to: 'index-4',

    //     },
    // 	{
    //         title: 'Dashboard-5',
    //         to: 'index-5',

    //     },

    //  ],
  },
  {
    title: "My Stakings",
    classsChange: "mm-collapse",
    iconStyle: <BiMoneyWithdraw size={30} color="#fff" />,
    to: "/stakingBonus",
  },
  
  {
    title: "My Directs",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineUsergroupAdd />,

    to: "/partner",
  },
  {
    title: "My Profile",
    classsChange: "mm-collapse",
    iconStyle: <CgProfile />,
    to: "/formSection",
  },
  {
    title: "My Team Report",
    classsChange: "mm-collapse",
    iconStyle: <GrGroup />,
    to: "/myteam",
  },

  {
    title: "My Level Bonus",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">trending_up</i>,

    to: "/Level",
  },

  {
    title: "My Growth Level Bonus",
    classsChange: "mm-collapse",
    iconStyle: <GiGrowth />,
    to: "/growthLevelBonus",
  },
  {
    title: "Coupon",
    classsChange: "mm-collapse",
    iconStyle: <FaTicketAlt  size={30} color="#fff"/>,
    to: "/coupon",
  },
  {
    title: "Coupon History",
    classsChange: "mm-collapse",
    iconStyle: <FaTicketAlt  size={30} color="#fff"/>,
    to: "/coupon_history",
  },
  {
    title: "Reward",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked size={30} color="#fff" />,
    to: "/reward",
  },
  {
    title: "My Rewards Income",
    classsChange: "mm-collapse",
    iconStyle: <LuBoxes />,
    to: "/reward&Recognition",
  },

  {
    title: "My Tree Structure",
    classsChange: "mm-collapse",
    iconStyle: <BiStreetView color="red" />,
    to: "/treeStructure",
  },
  {
    title: "My Growth Claim Bonus",
    classsChange: "mm-collapse",
    iconStyle: <BiMoneyWithdraw size={30} color="#fff" />,
    to: "/GrowthClaim",
  },
  {
    title: "My Staking Claim  Bonus",
    classsChange: "mm-collapse",
    iconStyle: <VscSymbolConstant size={30} color="#fff" />,
    to: "/claimBonus",
  },
  {
    title: "My Withdraw Staking Token",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked size={30} color="#fff" />,
    to: "/withDrawToken",
  },

  {
    title: "BTYC Buyback History",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked color="red" />,
    to: "/get-Buyback",
  },
  {
    title: "Buyback Withdwals",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked color="red" />,
    to: "/get-Buyback-withdraw",
  },
];
