import { Link, Navigate } from "react-router-dom";
import React, { useState } from "react";
import country from "../components/Pages/CoutryCode.json";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-hot-toast";
import logo from "../../bigbull.png";
import bg6 from "../../images/background/bg6.jpg";
import { setProfileInfo } from "../../helpers/api";
import NavMain from "../components/Pages/NavMain";
import { RefreshSatate } from "../../store/actions/AuthActions";

function KycStatus() {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });
  const dispatch = useDispatch();

  const [options, setOptions] = useState(country);
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  const [mobile, setMobile] = useState("");

  const handleChange = (event) => {
    const sopt = options[Number(event.target.value)];
    setSelectedOption({ ...sopt }); // Update the selected option in the state

    console.log(selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let mobCode = "+" + selectedOption?.country_code?.concat(mobile);
    let state = selectedOption?.country_name;
    console.log(name, email, state, mobCode);
    // if (!name || !email || !state || !mobCode) {
    //   toast.error("Please fill in all fields");
    //   return;
    // }
    if (validEmail && validPhoneNumber && state != "" && name != "") {
      // Valid form submission

      const formData = {
        name,
        email,
        state,
        mobCode,
        walletAddress,
      };

      setProfileInfo(formData).then((res) => {
        if (res?.status === 200) {
          dispatch(RefreshSatate(true));
          toast.success("Profile Updated Successfully !");
        } else {
          toast.error("Something Went Wrong !");
        }
      });
      // return;
    } else {
      toast.error("Please enter Valid Information");
    }
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setValidEmail(isValid);
  };

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    const regex = /^\d*$/; // Regex to match only numbers

    if (regex.test(inputPhoneNumber)) {
      setMobile(inputPhoneNumber);
    }
    //setMobile(inputPhoneNumber);

    // Mobile number regex pattern
    const phoneNumberRegex = /^[a-zA-Z0-9\-().\s]{10,15}$/;
    const isValid = phoneNumberRegex.test(inputPhoneNumber);
    setValidPhoneNumber(isValid);
  };

  return (
    <>
      <div style={{ height: "100vh" }}>
        <NavMain />
        <div className="page-wraper">
          <div className="browse-job login-style3">
            <div
              className="bg-img-fix overflow-scroll"
              style={{
                background: "#fff url(" + bg6 + ")",
                height: "100vh",
                overflow: "auto",
              }}
            >
              <div className="row gx-0">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
                  <div
                    id="mCSB_1"
                    className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                    style={{ maxHeight: "653px" }}
                  >
                    <div
                      id="mCSB_1_container"
                      className="mCSB_container"
                      style={{
                        position: "relative",
                        top: "0",
                        left: "0",
                        dir: "ltr",
                      }}
                    >
                      <div className="login-form style-2"  style={{ background: "#fff" }}>
                        <div className="card-body">
                          <div className="logo-header">
                            <Link to={"#"} className="logo">
                              <img
                                src={logo}
                                alt=""
                                className=" mCS_img_loaded"
                                width={100}
                              />
                            </Link>
                          </div>
                          <div className="nav nav-tabs border-bottom-0">
                            <div
                              className="tab-content w-100"
                              id="nav-tabContent"
                            >
                              <div
                                className="tab-pane fade active show"
                                id="nav-personal"
                              >
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="card">
                                      <div className="card-body pb-5">
                                        <h6 className="d-flex flex-wrap pb-3">
                                          Kyc Form
                                        </h6>
                                        <form
                                          class="row g-3 needs-validation"
                                          onSubmit={(e) => handleSubmit(e)}
                                        >
                                          <div class="col-md-12">
                                            <label
                                              for="validationCustom01"
                                              class="form-label"
                                            >
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="validationCustom01"
                                              value={name}
                                              placeholder="Enter Name"
                                              required
                                              onChange={(e) => {
                                                setName(e.target.value);
                                              }}
                                            />
                                          </div>
                                          <div class="col-md-12">
                                            <label
                                              for="validationCustom01"
                                              class="form-label"
                                            >
                                              Email Id
                                            </label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="Enter Email Id"
                                              id="validationCustom01"
                                              value={email}
                                              onChange={handleEmailChange}
                                              // onChange={(e) => {
                                              //   setEmail(e.target.value);
                                              // }}
                                              required
                                            />
                                            {!validEmail && (
                                              <span style={{ color: "red" }}>
                                                Invalid email
                                              </span>
                                            )}
                                          </div>
                                          <div class="col-md-12">
                                            <label
                                              for="validationCustom04"
                                              class="form-label"
                                            >
                                            Country
                                            </label>
                                            <select
                                              class="form-select"
                                              id="validationCustom04"
                                              required
                                              onChange={handleChange}
                                            >
                                              <option
                                                selected
                                                disabled
                                                value=""
                                              >
                                                select
                                              </option>{" "}
                                              {options?.map((option, i) => (
                                                <option
                                                  key={i + "country"}
                                                  value={i}
                                                >
                                                  {option?.country_name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <div class="col-md-12">
                                            <label
                                              for="validationCustom04"
                                              class="form-label"
                                            >
                                              Mobile Number
                                            </label>
                                            <div className="input-group mb-3">
                                              <span className="input-group-text">
                                                + {selectedOption?.country_code}
                                              </span>

                                              <input
                                                type="text"
                                                required
                                                className="form-control"
                                                value={mobile}
                                                // onChange={(e) => {
                                                //   setMobile(e.target.value);
                                                // }}

                                                onChange={
                                                  handlePhoneNumberChange
                                                }
                                                placeholder="Enter mobile number"
                                              />
                                            </div>
                                            {!validPhoneNumber && (
                                              <span style={{ color: "red" }}>
                                                Invalid mobile number
                                              </span>
                                            )}

                                            <div className="text-center bottom pt-2">
                                              <input
                                                type="submit"
                                                className="btn btn-primary button-md btn-block"
                                              />
                                              {/* Submit
                                            </input> */}
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default KycStatus;
