import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

import ConnectButtons from "../../components/Button/ConnectButton";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../helpers/getWeb3";

const Header = ({ onNote }) => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });
  const { userAllDetails } = useSelector((action) => {
    return action.auth;
  });
  const [ContdashInfo, setContDashInfo] = useState([]);

  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  const { changeBackground } = useContext(ThemeContext);

  //  console.log(userAllDetails?.data?.length)

  const chageTheme = (e) => {
    if (e.target.checked) {
      changeBackground({ value: "dark", label: "dark" });
    } else {
      changeBackground({ value: "light", label: "dark" });
    }
  };

  useEffect(() => {
    getUserInfo(walletAddress).then((res) => {
      setContDashInfo(res);
    });
  }, []);
  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                Hello Users{" "}
                {userAllDetails?.data?.length > 0
                  ? userAllDetails?.data[0]?.id
                  : null}{" "}
                !
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div className="input-group">
                  <div className="ms-1 me-1">
                    <div
                      className={`btn-xxs btn ${
                        Number(ContdashInfo?.cappingUsed?._hex) / 1e18 >=
                        Number(ContdashInfo?.cappingLimit?._hex) / 1e18
                          ? "bg-danger"
                          : "bg-success"
                      } text-white`}
                    >
                    
                      {Number(ContdashInfo?.cappingUsed?._hex) / 1e18 >=
                      Number(ContdashInfo?.cappingLimit?._hex) / 1e18
                        ? "In Active"
                        : "Active"}
                    </div>
                  </div>
                  <ConnectButtons />
                  <div className="p-1 pt-0 mob-view-theme">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      onChange={(e) => chageTheme(e)}
                    />

                    <label for="checkbox" class="checkbox-label">
                      <i class="fas fa-moon"></i>
                      <i class="fas fa-sun"></i>
                      <span class="ball"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
