import React, { useState, useEffect } from "react";

import ConnectButtons from "../Button/ConnectButton";



const NavMain = () => {


  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div className="input-group">
              
                  <ConnectButtons />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavMain;
