import React, { useEffect, useState, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import { getTeamInfo } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
const MyTeam = () => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });

  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTeamInfo(walletAddress, page).then((res) => {
     
      setApidata(res);
      setLoading(true);
    });
  }, [page, walletAddress]);

  // const handleChange = (e, p) => {
  //   getTeamInfo(walletAddress, p).then((res) => {
  //     setTotal(res?.total);
  //     setApidata(res?.data);
  //     setLoading(true);
  //   });
  //   //console.log(p, page, "page:::");
  //   setPage(p);
  // };
  return (
    <>
      {!loading ? (
        <Loader/>
      ) : (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">My Team</h4>
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                            
                            <th>User Address</th>
                            <th> User Id</th>
                            <th> User Name</th>
                            <th>Joining Amount(USDT)</th>
                            <th> Total Investment(USDT)</th>
                            <th> Registration Date</th>
                            {/* <th>Price</th>
                          <th>Total</th>
                          <th className="text-end">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {apidata?.length > 0 ? (
                            apidata.map((e, index) => (
                              <tr key={index}>
                            
                                <td>
                                  {" "}
                                  {e?.user?.slice(0, 6)}...
                                  {e?.user?.slice(-5)}
                                </td>
                                <td>{e?.uId}</td>
                                <td className="text-capitalize">{e?.name}</td>
                                <td> {e?.joining_amount}</td>
                                <td> {e?.currentInvestmentV2}</td>
                                <td>
                                  {" "}
                                  {moment(e.transaction_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                             
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                  No Data Found.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info"></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyTeam;
