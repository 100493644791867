import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./tree.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { getUserAllDetails } from "../../../helpers/getWeb3";
import { getTreeView, getUserRandomidToId } from "../../../helpers/api";
import { Loader } from "../Dashboard/Loader";
import logo from "../../../bigbull.png";
import moment from "moment/moment";
const TreeStructure = () => {
  const [apidata, setApidata] = useState([]);
  const [loading, setLoading] = useState(false);
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });
  const [steps, setsteps] = useState([]);
  function getLevelData(address, ar) {
    var treedata = {
      name: "",
      children: [],
    };
    getUserAllDetails(address).then(async (res) => {
      // console.log("fgsd", res)
      const apidata = await getUserRandomidToId(Number(res?.userId?._hex));
      console.log(apidata, "Api");
      treedata["dateofJoining"] = apidata[0]?.joining;
      treedata["totalTeam"] = apidata[0]?.total_team;
      treedata["totalTeamBusiness"] = apidata[0]?.total_team_business;
      treedata["rank"] = apidata[0]?.rank;
      treedata["direct"] = apidata[0]?.direct;
      treedata["directBusiness"] = apidata[0]?.direct_business;
      treedata["teamBusiness"] = apidata[0]?.total_team_business;
      treedata["id"] = apidata[0]?.id;
      try {
        getTreeView(Number(res?.userId?._hex)).then((res) => {
          if (res?.length > 0) {
            res?.map((item, i) => {
              const obj = {
                name: item?.id.toString(),
                children: [],
                ...item,
              };
              obj.children = [{ ...obj }];
              treedata.children.push(obj);
            });

            setApidata([{ ...treedata }]);
            setLoading(true);
            if (ar) {
              setsteps(ar);
            }
          } else {
            setLoading(true);
            setApidata([{ ...treedata, children: [] }]);
          }
        });
      } catch (e) {
        setLoading(true);
        setApidata([{ ...treedata, children: [] }]);
      }
    });
  }
  useEffect(() => {
    setsteps([walletAddress]);
    getLevelData(walletAddress);
  }, [walletAddress]);

  const tooltip = (chl) => (
    <Tooltip id="multi-api-tooltip">
   
      <strong>User_id:</strong> {chl?.randomId} <br />
      <strong>Date of Joining:</strong>{" "}
      {moment(chl?.joining).format("DD-MM-YYYY")} <br />
      <strong>Current Rank:</strong> {chl?.rank} <br />
      <strong>Total Directs:</strong> {chl?.direct} <br />
      <strong>Total Team:</strong> {chl?.total_team} <br />
      <strong>Direct Business:</strong> $ {chl?.direct_business} <br />
      <strong>Total Team Business:</strong> $ {chl?.total_team_business} <br />
    </Tooltip>
  );

  const tooltipmMain = (item) => (
    <Tooltip id="multi-api-tooltip">
      {/* {console.log(item, "item")} */}
      <strong>User_id:</strong> {item?.id} <br />
      <strong>Date of Joining: </strong>
      {moment(item?.dateofJoining).format("DD-MM-YYYY")} <br />
      <strong>Total Team:</strong> {item?.totalTeam} <br />
      <strong>Current Rank:</strong> {item?.rank} <br />
      <strong>Total Directs:</strong> {item?.direct} <br />
      <strong>Direct Business:</strong> $ {item?.directBusiness} <br />
      <strong>Team Business:</strong> $ {item?.teamBusiness} <br />
    </Tooltip>
  );
  return (
    <>
      <div className="card">
        <div className="card-body">
          <section
            className="nft__area primary-bg  pb-5"
            style={{ height: "100%" }}
          >
            {!loading ? (
              <Loader />
            ) : (
              <div className="container">
                <h3 clasName="my-3" style={{ textAlign: "center" }}>
                  Your Level Tree
                </h3>
                <div className="row">
                  <button
                    className=" btn userTree"
                    style={{
                      border: "1px solid #362465",
                      width: "max-content",
                    }}
                    onClick={async () => {
                      let ar = [...steps];

                      if (ar.length > 1) {
                        ar.pop();
                        if (ar.length > 1) {
                          getLevelData(ar[ar.length - 1]);
                          setsteps(ar.length > 0 ? [...ar] : []);
                        } else {
                          setsteps([walletAddress]);
                          getLevelData(walletAddress);
                        }
                      } else {
                        setsteps([walletAddress]);
                        getLevelData(walletAddress);
                      }
                    }}
                  >
                    {"< Go back"}
                  </button>
                  <button
                    className=" btn userTree mx-1"
                    style={{
                      border: "1px solid #362465",
                      width: "max-content",
                    }}
                    onClick={() => {
                      getLevelData(walletAddress);
                      setsteps([walletAddress]);
                    }}
                  >
                    {"Root"}
                  </button>
                  <div class="tree col-lg-12 col-md-12 mx-auto treresp">
                    {apidata?.length > 0
                      ? apidata?.map((item, i) => {
                          return (
                            <ul
                              style={{
                                // minWidth: "2700px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <li>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipmMain(item)}
                                >
                                  <a href="#" style={{ width: "100px" ,height:"100px" }}>
                                    <div>
                                      <img src={logo} alt="" width={50} />
                                      <h6 className=" text-center tree-para fw-bold  pt-1" >
                                        <span className="userTree text-warning"></span>
                                        &nbsp;{item?.id}
                                      </h6>
                                    </div>
                                  </a>
                                </OverlayTrigger>
                                <ul className="subtree table-responsive pb-3">
                                  {item.children.map((chl, j) => {
                                    return (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltip(chl)}
                                      >
                                        <li
                                          onClick={() => {
                                            const ar = [...steps];
                                            ar.push(chl.user);
                                            getLevelData(chl.user, ar);
                                          }}
                                        >
                                          <a href="#" style={{width: "100px" ,height:"100px"}}  >
                                            <div className="p-0">
                                              <img
                                                src={logo}
                                                alt=""
                                                width={50}
                                              />
                                              <h6 className="text-center tree-para  pt-1">
                                                <span className="userTree text-success"></span>
                                                &nbsp;{chl.randomId}
                                              </h6>

                                             
                                            </div>
                                         
                                          </a>
                                        </li>
                                      </OverlayTrigger>
                                    );
                                  })}
                                </ul>
                              </li>
                            </ul>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default TreeStructure;
