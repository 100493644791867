import React, { useEffect, useState, useRef } from "react";
//import {Link} from 'react-router-dom';
import { Nav, Tab } from "react-bootstrap";
import Select from "react-select";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGrowthLevelIncome, getLevelIncome } from "../../../helpers/api";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { getUserDirectWithTime } from "../../../helpers/getWeb3";
import { Loader } from "../Dashboard/Loader";
const GrowthLevelBonus = () => {
  const options2 = [
    { value: "ALL", label: "ALL" },
    { value: "1", label: "Level 1" },
    { value: "2", label: "Level 2" },
    { value: "3", label: "Level 3" },
    { value: "4", label: "Level 4" },
    { value: "5", label: "Level 5" },
    { value: "6", label: "Level 6" },
    { value: "7", label: "Level 7" },
    { value: "8", label: "Level 8" },
    { value: "9", label: "Level 9" },
    { value: "10", label: "Level 10" },
    { value: "11", label: "Level 11" },
    { value: "12", label: "Level 12" },
    { value: "13", label: "Level 13" },
    { value: "14", label: "Level 14" },
    { value: "15", label: "Level 15" },
    { value: "16", label: "Level 16" },
    { value: "17", label: "Level 17" },
    { value: "18", label: "Level 18" },
    { value: "19", label: "Level 19" },
    { value: "20", label: "Level 20" },
    { value: "21", label: "Level 21" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    value: "ALL",
    label: "ALL",
  });
  const { walletAddress, userToken, userAllDetails } = useSelector((action) => {
    return action.auth;
  });
 
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  function getLevelData(p) {
    getGrowthLevelIncome(
      walletAddress,
      selectedOption.value,
   
      p
    ).then((res) => {
      console.log(res, "res");
      setTotal(res?.total);
      setApidata(res?.data);
      setLoading(true);
    });
  }

  useEffect(() => {
    getLevelData(page);
  }, [walletAddress, page, selectedOption]);

  const handleChange = (e, p) => {
    getLevelData(p);
   
    setPage(p);
  };

  useEffect(() => {
    if (walletAddress)
      getUserDirectWithTime(
        walletAddress,
        userAllDetails?.data[0]?.direct
      ).then((res) => {
        console.log(Number(res?._hex), "res:::");
      });
  }, [walletAddress, userAllDetails]);

  return (
    <div>
      {!loading ? (
        <Loader />
      ) : (
        <Tab.Container defaultActiveKey="Buy">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="d-flex flex-wrap">
                    <Select
                      options={options2}
                      defaultValue={options2[0]}
                      onChange={setSelectedOption}
                      className="custom-react-select ms-4 me-2"
                    />
                  </div>
                </div>
                <div className="card-body">
                  <Tab.Content>
                    <Tab.Pane eventKey="Buy">
                      <Tab.Container defaultActiveKey="BTH">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="heading">Growth Level Bonus</h4>
                        </div>
                        <Tab.Content>
                          <Tab.Pane eventKey="BTH">
                            <div className="table-responsive dataTablehistory">
                              <div
                                id="bthdata_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example"
                                  className="table dataTable shadow-hover display"
                                  style={{ minWidth: "845px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Sender</th>
                                   
                                      <th>Level</th>
                                      <th>Staked Amount (mBTYC)</th>
                                      <th>Share % </th>
                                    
                                      <th>Growth Share %</th>
                                      <th>Status</th>
                                      <th>Income Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {apidata?.length > 0 ? (
                                      apidata.map((e, ind) => {
                                        return (
                                          <tr key={ind}>
                                            <td>
                                              {e?.sender?.slice(0, 6)}...
                                              {e?.sender?.slice(-5)}
                                            </td>                                         
                                            <td>{e?.level}</td>
                                            <td>{e?.amount / 1e18}</td>
                                            <td>{e?.share / 10}</td>                                           
                                            <td>{e?.growthShare / 10}</td>

                                            <td>
                                              {e?.status === "Inactive" ? (
                                                <span
                                                  class="badge bg-danger"
                                                  text-secondary
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Direct Compulsery"
                                                >
                                                  {" "}
                                                  Inactive
                                                </span>
                                              ) : (
                                                <span class="badge bg-success">
                                                  Active
                                                </span>
                                              )}{" "}
                                            </td>

                                            <td>
                                              {" "}
                                              {moment(
                                                Number(e.block_timestamp) * 1000
                                              ).fromNow()}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={7}>
                                          <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                            No Data Found.
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="application-tbl1_paginate"
                                  >
                                    <Pagination
                                      color="primary"
                                      count={Math.ceil(total / 10)}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>

                         
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      )}
    </div>
  );
};

export default GrowthLevelBonus;
