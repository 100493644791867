import React, { useEffect, useState, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment/moment";
import { getBuyBackData } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
import Countdown, { zeroPad } from "react-countdown";

const BuyBack = () => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });

  const [apidata, setApidata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBuyBackData(walletAddress).then((res) => {
    
      setApidata(res?.data);
      setLoading(true);
    });
  }, [walletAddress]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="header-label fs-14 grey">
          <span className="rounded" style={{ width: "56px" }}>
            Days {zeroPad(days)}
          </span>
          :{" "}
          <span className="rounded ms-1">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </span>
      );
    }
  };

  const Completionist = () => <span className="text-danger">Expired</span>;

  return (
    <>
      {!loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">BTYC Buyback History</h4>
                
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                            <th>Txn Hash</th>
                            <th>User Address</th>
                            <th>Amount (USDT)</th>   
                            <th>Amount (BTYC)</th>   
                            <th> Locked </th>                      
                            <th> Joined On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {apidata?.length > 0 ? (
                            apidata?.map((e, index) => (
                              
                              <tr key={index}>
                                <td>
                                  {" "}
                                  {e?.transaction_id?.slice(0, 6)}...
                                  {e?.transaction_id?.slice(-5)}
                                  <a
                                    target="_blank"
                                    href={`https://merklescan.com/tx/${e?.transaction_id}`}
                                  >
                                    <i className="fas fa-external-link-alt fs-10 ms-1 text-success"></i>
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  {e?.user?.slice(0, 6)}...
                                  {e?.user?.slice(-5)}
                                </td>
                                <td className="text-capitalize">
                                  {e?.package/1e18}
                                </td>
                                <td className="text-capitalize">
                                  {e?.amount/1e18}
                                </td>
                              
                                <td>
                                <Countdown
                                    date={
                                      Date.now() +
                                      (moment(e?.unlockdtimestamp * 1000)
                                      
                                        .valueOf() -
                                        Date.now())
                                    }
                                    renderer={renderer}
                                  />
                                </td>

                                <td>
                                  {" "}
                                  {moment(
                                    Number(e.block_timestamp) * 1000
                                  ).fromNow()}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                  No Data Found.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
              
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyBack;
