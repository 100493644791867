import React from "react";

import { useSelector } from "react-redux";

import logo from "../../../logo.png";

const Form = () => {
  const {userAllDetails } = useSelector((action) => {
    return action.auth;
  });


  return (
    <div>
  
        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content ">
                  <div className="cover-photo rounded"></div>
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    <img
                      src={logo}
                      className="img-fluid rounded-circle"
                      alt="profile"
                    />
                  </div>
                  <div className="profile-details">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0 text-capitalize">{userAllDetails?.data[0]?.customer_name}</h4>
                      <p>Name</p>
                    </div>
                    <div className="profile-email px-2 pt-2">
                      <h4 className="text-muted mb-0">{userAllDetails?.data[0]?.email_id}</h4>
                      <p>Email</p>
                    </div>
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-muted mb-0">{userAllDetails?.data[0]?.country}</h4>
                      <p>Country</p>
                    </div>
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-muted mb-0">{userAllDetails?.data[0]?.mobile_no}</h4>
                      <p>Mobile No</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    </div>
  );
};

export default Form;
