import React, { useEffect, useState } from "react";

import { Button, Modal, Nav, Tab } from "react-bootstrap";

import { FiBox } from "react-icons/fi";
import { FaCoins } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { MdSwapHorizontalCircle } from "react-icons/md";

import { ImCoinDollar } from "react-icons/im";
import { GiTakeMyMoney, GiGrowth, GiLevelThreeAdvanced } from "react-icons/gi";
import {
  MdAttachMoney,
  MdDoNotDisturbOnTotalSilence,
  MdOutlineDirectionsWalk,
} from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { RiTeamLine } from "react-icons/ri";
import { CiCoinInsert } from "react-icons/ci";
//images
import coin from "./../../../images/coin.png";
import { copy_refral, copy_wallet } from "../../../helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  BuyPackage,
  getBalanceUser,
  claimRewards,
  getStakingRewards,
  getTokenPrice,
  getAvailableForWithdrawToken,
  claimStakeToken,
  getAvailableRewardToken,
  getUserInfo,
  getStakingLength,
  getStakingDetails,
  FarmingPackage,
  getBtycRateDollar,
  getmBtycRateDollar,
  getBTYCBalanceUser,
  getFarmingRewards,
  claimFarmRewards,
  getFarmingTotalStake,
  getFutureFarminToken,
  BuyBackBtyc,
  getBtycBuyRateDollar,
} from "../../../helpers/getWeb3";
import { toast } from "react-hot-toast";
import {
  RewardIncomeView,
  RewardIncomeWithdraw,
  addRewardUsdtAmont,
  getAllBusiness,
  getBuyBackWithDrwalData,
  getCurrentPrice,
  getUserAllIncome,
  getUserDashInfo,
  getUserRandomId,
  setRewardIncomeByUser,
  setWithwalDetails,
} from "../../../helpers/api";
import { Loader } from "./Loader";
import { userAllDetailsAction } from "../../../store/actions/AuthActions";
import moment from "moment/moment";
import {
  TOKEN_ADDRESS,
  farmingArr,
  mBTYC_Address,
  packageArr,
} from "../../../helpers/config";

const Home = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [btnLoader, setBtnLoader] = useState(false);

  const [value, setValue] = useState(25);
  const [randomId, setRandomId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [refreshBuy, setBuyRefresh] = useState(false);
  const [regloading, setRegLoading] = useState(false);
  const [farmloading, setFarmLoading] = useState(false);
  const [buBackLoading, setBuyBackLoading] = useState(false);
  const [tokenBalance, setTokenBalnce] = useState(0);
  const [tokenBtycBalance, setTokenBtycBalnce] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [currentPriceRestke, setCurrentPriceRestake] = useState(0);
  const [claimReward, setClaimReward] = useState(0);
  const [growthLevel, setGrowthLevl] = useState(0);
  const [stakeLevel, setStakeLevel] = useState(0);
  const [wthdrawStakingToken, setWithdrawStakeToken] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [userIncome, setUserIncome] = useState([]);
  const [currPriceApi, setCurrentPriceApi] = useState('')
  const [rewardIncome, setRewardIncome] = useState([]);
  const [ContdashInfo, setContDashInfo] = useState([]);
  const [packageDetails, setPackageDetails] = useState({});
  const [stakeLength, setStakeLength] = useState("");
  const { walletAddress, userExist, userToken } = useSelector((action) => {
    return action.auth;
  });
  const [basicModal, setBasicModal] = useState(false);
  const [duration, setDuration] = useState("");
  const [farmingTokenAmt, setFarmingTokenAmt] = useState("");
  const [farmingDollarAmt, setFarmingDollarAmt] = useState("");
  const [farmingDetails, setfarmingDetails] = useState({});
  const [btycRate, setBtycRate] = useState("");
  const [farminTotalamt, setFarmingTotalamt] = useState("");
  const [mbtycRate, setmBtycRate] = useState("");
  const [mBtycFarmReward, setmBtycFarmRate] = useState("");
  const [totalFarmStake, settotalFarmStake] = useState("");
  const [business, setBusiness] = useState("");
  const [btycSellAmount, setBtycSellAmount] = useState("")
  const [btycSellAmountDollar, setBtycSellAmountDollar] = useState("")
  const [buybackWithData, setBuyBackWithData] = useState('')
  const [refreshWith, setRefreshWith] = useState(false)
  const [btnWithLoader, setBtnWithLoader] = useState(false)

  useEffect(() => {
    setRewardIncomeByUser(walletAddress);
    getAvailableForWithdrawToken(walletAddress).then((res) => {
      setWithdrawStakeToken(Number(res?._hex));
    });

    getBalanceUser(walletAddress, mBTYC_Address).then((res) => {
      setTokenBalnce(res?.formatted);
    });
    getBTYCBalanceUser(walletAddress, TOKEN_ADDRESS).then((res) => {
      setTokenBtycBalnce(res?.formatted);
    });
    getTokenPrice(mBTYC_Address).then((res) => {
      setCurrentPrice(res);
    });
    getTokenPrice(mBTYC_Address).then((res) => {
      setCurrentPriceRestake(res);
    });
    getFutureFarminToken(walletAddress).then((res) => {
      setFarmingTotalamt(res);
    });
    getAllBusiness(walletAddress).then((res) => {
      setBusiness(res);
    });
    const roiinterwal = setInterval(() => {
      var sum = claimReward;

      getStakingRewards(walletAddress).then((res1) => {
        setStakeLevel(Number(res1?._hex));
        sum += Number(res1?._hex);
        if (sum > 0) {
          setClaimReward(sum);
        }
      });
      getAvailableRewardToken(walletAddress).then((res2) => {
        setGrowthLevl(Number(res2?.avlGrowth?._hex));
        sum = Number(res2?.avlGrowth?._hex);
        if (sum > 0) {
          setClaimReward(sum);
        }
      });
    }, 5000);


    return () => clearInterval(roiinterwal);
  }, [walletAddress, refresh]);

  function Buy() {
    if (stakeLength <= value) {
      if (userExist && value > 0) {
        BuyPackage(walletAddress, value, setRegLoading, setBuyRefresh);
      }
    } else {
      toast.error(
        "Re-Staking Amount must be greater or equal than previous package "
      );
    }
  }

  function FarmingBtyc() {
    // console.log(farmingDollarAmt,"farmingDollarAmt")
    if (farmingDollarAmt) {
      if (farmingDollarAmt > 1) {
        if (duration) {
          FarmingPackage(
            walletAddress,
            farmingDollarAmt,
            duration,
            setFarmLoading,
            setBuyRefresh
          );
        } else {
          toast.error("Please Select Farming Tenure!");
        }
      } else {
        toast.error("amount must be greater than 1 ");
      }
    } else {
      toast.error("Please Enter amount !");
    }
  }

  function BuyBackBtycLock() {
    console.log(btycSellAmount, "farmingDollarAmt")
    if (btycSellAmountDollar) {
      if (btycSellAmountDollar > 1) {
        BuyBackBtyc(
          walletAddress,
          btycSellAmountDollar,
          setBuyBackLoading,
          setBuyRefresh
        );
      } else {
        toast.error("amount must be greater than 1 ");
      }
    } else {
      toast.error("Please Enter amount !");
    }
  }
  const userData = {
    usdtRewardWallet: "0",
    usdtRewardWithdrawal: userInfo?.rewardUSDT,
    uId: userInfo?.id,
    user: walletAddress,
    amountUsd: userInfo?.rewardUSDT,
    amount: userInfo?.rewardUSDT * (1 / currentPrice),
    admin_commision: (userInfo?.rewardUSDT * (1 / currentPrice) * 10) / 100,
    admin_percent: "10",
    net_amount:
      userInfo?.rewardUSDT * (1 / currentPrice) -
      (userInfo?.rewardUSDT * (1 / currentPrice) * 10) / 100,
    hash: "",
    paid_status: 0,
  };

  function claimRewardAmt() {
    if (userInfo?.rewardUSDT > 0) {
      setBtnLoader(true);
      addRewardUsdtAmont(userData).then((res) => {
        if (res?.status === 200) {
          getUserDashInfo(walletAddress).then((res) => {
            setUserInfo(res[0]);
          });
          setBtnLoader(false);

          toast.success("Claim Successful...");
        } else {
          setBtnLoader(false);
          toast.error("Something Went Wrong !");
        }
      });
    } else {
      setBtnLoader(false);
      toast.error("amount must be greater than 0!");
    }
  }

  function claimFarmReward(walletAddress) {
    if (mBtycFarmReward > 0) {
      claimFarmRewards(walletAddress, setmBtycFarmRate);
    } else {
      toast.error("amount must be greater than 0!");
    }
  }

  useEffect(() => {
    if (walletAddress) {
      getUserRandomId(walletAddress).then((res) => {
        if (res?.data?.length > 0) {
          setLoading(false);
          setRandomId(res?.data[0]?.id);
          dispatch(userAllDetailsAction(res));
        }
      });
      getStakingLength(walletAddress).then((res) => {
        if (res) {
          getStakingDetails(walletAddress, res - 1).then((res1) => {
            setStakeLength(Number(res1?.package?._hex / 1e18));
          });
        }
      });
    }
  }, [walletAddress]);

  useEffect(() => {
    getUserDashInfo(walletAddress).then((res) => {
      setUserInfo(res[0]);
    });
    getUserAllIncome(walletAddress).then((res) => {
      setUserIncome(res);
    });
    if (walletAddress) {
      RewardIncomeView(walletAddress).then((res) => {
        setRewardIncome(res[0]?.rewardWallet);
      });
      getUserInfo(walletAddress).then((res) => {
        setContDashInfo(res);
      });
    }
    if (value) {
      getPackageDetails(value).then((res) => {
        setPackageDetails(res);
      });
    }
    if (duration) {
      getFarmingPackReturn(duration).then((res) => {
        // console.log(res, "dur");
        setfarmingDetails(res);
      });
    }
  }, [walletAddress, mBTYC_Address, value, refresh, refreshBuy, duration]);

  async function getPackageDetails(value) {
    const pDetails = await packageArr.find((res) => res.package === value);
    return pDetails;
  }

  async function getFarmingPackReturn(duration) {
    const fDetails = await farmingArr.find((res) => res.duration === duration);
    return fDetails;
  }

  const maxGreenWidth = 90;
  const cappingLimit = Number(ContdashInfo?.cappingLimit?._hex) / 1e18;
  const cappingUsed = Number(ContdashInfo?.cappingUsed?._hex) / 1e18;
  const progress = (cappingUsed / cappingLimit) * 100;

  const backgroundColor = progress > maxGreenWidth ? "bg-danger" : "bg-success";

  const progressBarStyle = {
    width: `${progress}%`,
  };

  useEffect(() => {
    getBtycRateDollar().then((res) => {
      setBtycRate(res);
    });
    getmBtycRateDollar().then((res) => {
      setmBtycRate(res);
    });
    getFarmingRewards(walletAddress).then((res) => {
      setmBtycFarmRate(res);
    });
    getFarmingTotalStake(walletAddress).then((res) => {
      settotalFarmStake(res?.totalStakeUSD?._hex / 1e18);
    });
    //  ------------- Btyc New Sale
    getBtycBuyRateDollar().then((res) => {

      if (res) {
        setCurrentPriceApi(res)
      }

    })

    getBuyBackWithDrwalData(walletAddress).then((res) => {

      setBuyBackWithData(res?.data[0])
    })

  }, [walletAddress, refreshWith]);
  // console.log(userInfo, "userInfo");

  function changePriceFarming(value, type) {
    if (type == 1) {
      var usd_amount = Number(value) / btycRate;
      setFarmingDollarAmt(usd_amount);
      setFarmingTokenAmt(value);
    } else {
      var btyc_amount = Number(value) * btycRate;
      setFarmingTokenAmt(btyc_amount);
      setFarmingDollarAmt(value);
    }
  }

  const changePriceBtycEx = (value, type) => {

    if (type === 1) {
      const usd_amount = Number(value) / currPriceApi;
      setBtycSellAmount(value);
      setBtycSellAmountDollar(usd_amount);
    } else {
      const btyc_amount = Number(value) * currPriceApi;
      setBtycSellAmount(btyc_amount);
      setBtycSellAmountDollar(value);
    }
  };
  const generateUniqueNumber = () => {
    return Math.floor(Math.random() * 1000000000); // Generate a random number up to 1 billion
  };
  function withDwalBuyBackAmount() {
    setBtnWithLoader(true)
    if (buybackWithData?.walletBalance >= 10) {
      const formData = {
        userId: userInfo?.id,
        user: walletAddress,
        withAmt: Number(buybackWithData?.walletBalance),
        adminAmt: buybackWithData?.walletBalance * 5 / 100,
        netAmt: (buybackWithData?.walletBalance) - (buybackWithData?.walletBalance * 5 / 100),
        withreferenceno: generateUniqueNumber(), // Add random unique number
      };

      setWithwalDetails(formData).then((res) => {
        console.log(res, "res::::")
        if (res?.message == "Withdrawal successful") {
          setRefreshWith(true)
          toast.success(res?.message);
          setBtnWithLoader(false)

        } else {
          toast.error(res?.message);
          setBtnWithLoader(false)

        }
      });

    } else {
      toast.error("Amount must be  10$ !");
      setBtnWithLoader(false)

    }


  }

  return (
    <>
      <div className="row">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="card">
                <div class=" d-block card-header">
                  <div class="card-title text-center h6">Cap Limit Tracker</div>
                  <hr />
                  <div className="card-title text-center pb-2">
                    Remaining Cap Limit -
                    <span className="text-danger fw-bolder">
                      {" "}
                      ${" "}
                      {(
                        Number(ContdashInfo?.cappingLimit?._hex) / 1e18 -
                        Number(ContdashInfo?.cappingUsed?._hex) / 1e18
                      )?.toFixed(2)}{" "}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <span className="headingTxt">Total Cap Limit </span>
                      <div className="amntTxt">
                        {" "}
                        $ {Number(ContdashInfo?.cappingLimit?._hex) / 1e18}{" "}
                      </div>
                    </div>

                    <div>
                      <span className="headingTxt"> Cap Limit Utilized </span>
                      <div className="amntTxt">
                        {" "}
                        ${" "}
                        {(
                          Number(ContdashInfo?.cappingUsed?._hex) / 1e18
                        )?.toFixed(2)}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="progress">
                    <div
                      role="progressbar"
                      className={`progress-bar ${backgroundColor}`}
                      style={progressBarStyle}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {/* {console.log(business,"d")} */}
            <div className="col-xl-8">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bubles p-5">
                    <div className="card-body">
                      <div className="buy-coin  bubles-down">
                        <div>
                          <h3>My Current Rank -{userInfo?.rank ? userInfo?.rank : "N/A"}</h3>
                          <h6>
                            My Next Rank-
                            {userInfo?.rank?.toLowerCase()?.replace(' ', '') ==
                              "executive"
                              ? "Team Leader"
                              : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "teamleader"
                                ? "Manager"
                                : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "manager"
                                  ? "Sr Manager"
                                  : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "srmanager"
                                    ? "Branch Manager"
                                    : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "branchmanager"
                                      ? "Zonal Manager"
                                      : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "zonalmanager"
                                        ? "State Head"
                                        : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "statehead"
                                          ? "Country Head"
                                          : userInfo?.rank?.toLowerCase()?.replace(' ', '') == "vicepresident"
                                            ? "Director"
                                            : "Executive"}
                          </h6>
                        </div>
                        <div className="coin-img">
                          <img src={coin} className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-danger">
                    <div className="card-body  p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="flaticon-381-calendar-1"></i>
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Name | User ID</p>
                          <h4 className="text-white">
                            {userInfo?.name} | {userInfo?.id}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <BsFillCalendarDateFill />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Joining Date & Time</p>
                          <h4 className="text-white">
                            {moment(userInfo?.joining).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-info">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <AiOutlineTeam />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Total Team</p>
                          <h4 className="text-white">{userInfo?.total_team}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-primary">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <GiTakeMyMoney />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Total Team Business</p>
                          <h4 className="text-white">
                            {userInfo?.total_team_businessv2} USDT
                          </h4>
                          <h6 className="text-white">
                            Old - {userInfo?.total_team_business} USDT
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-secondary">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <MdOutlineDirectionsWalk />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">My Directs</p>
                          <h4 className="text-white">{userInfo?.direct}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-danger ">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Directs Business</p>
                          <h4 className="text-white">
                            {Number(ContdashInfo?.directBusiness?._hex) / 1e18}{" "}
                            USDT
                          </h4>
                          <h6 className="text-white">
                            Old-{userInfo?.direct_business} USDT
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#393937" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-primary text-primary">
                          <AiOutlineTeam />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">Today's Team</p>
                          <h4 className="mb-0 text-white">
                            {userInfo?.daily_team}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#bf7b20" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-warning text-warning">
                          <ImCoinDollar />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">Today's Business</p>
                          <h4 className="mb-0 text-white">
                            {business?.today} USDT
                          </h4>
                          {/* <span className="badge badge-warning">+3.5%</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#094c56" }}
                  >
                    <div className="card-body  p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-danger text-danger">
                          <RiTeamLine />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">Month's Team</p>
                          <h4 className="mb-0 text-white">
                            {userInfo?.monthly_team}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#2d6696" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <CiCoinInsert />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">Month's Business </p>
                          <h4 className="mb-0  text-white">
                            {business?.last_thirty_days}    USDT


                          </h4>
                          {/* <span className="badge badge-success">-3.5%</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-warning">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <FaCoins />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">My Staking</p>
                          <h4 className="text-white">
                            {Number(ContdashInfo?.totalStakeUSD?._hex) / 1e18}{" "}
                            USDT
                          </h4>
                          <h5 className="text-white">
                            {Number(ContdashInfo?.totalTokenStake?._hex) / 1e18}
                            <span className=""> mBTYC</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#649037" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <GiLevelThreeAdvanced />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">
                            My Level Bonus <span>(After Deduction)</span>{" "}
                          </p>
                          <h4 className="text-white">
                            {Number(ContdashInfo?.levelBonusUsd?._hex) / 1e18}{" "}
                            USDT
                          </h4>
                          <h5 className="mb-0 text-white">
                            {Number(ContdashInfo?.levelBonus?._hex) / 1e18}{" "}
                            mBTYC
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#9341c1" }}
                  >
                    {/* {console.log(userIncome,"income::")} */}
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-danger text-ifo">
                          <MdAttachMoney />
                        </span>
                        <div className="media-body text-white">
                          <h5 className="text-white">My Staking Bonus</h5>
                          <h4 className="text-white">
                            {(stakeLevel / 1e18)?.toFixed(5)} USDT
                          </h4>
                          <h6 className="text-white">
                            Withdrawn | Available Bonus{" "}
                          </h6>
                          <p className="text-white">
                            {(
                              userIncome?.staking_bonus_USD / 1e18 +
                              stakeLevel / 1e18 -
                              stakeLevel / 1e18
                            )?.toFixed(5)}{" "}
                            | {(stakeLevel / 1e18)?.toFixed(5)} USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <h5 className="text-white">My Growth Level Bonus </h5>
                          <h4 className="text-white">
                            {(
                              userIncome?.growth_claim_bonus_USD / 1e18 +
                              growthLevel / 1e18
                            )?.toFixed(5)}{" "}
                            USDT
                          </h4>
                          <h6 className="text-white">
                            Withdrawn | Available Bonus
                          </h6>

                          <p>
                            {(
                              userIncome?.growth_claim_bonus_USD / 1e18 +
                              growthLevel / 1e18 -
                              growthLevel / 1e18
                            )?.toFixed(5)}{" "}
                            | {(growthLevel / 1e18)?.toFixed(5)} USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#90377f" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <FiBox />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">My Rewards</p>
                          <h4 className="mb-0 text-white">
                            {/* $ {userIncome?.rewards} */}$ 0
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-primary">
                    <div className="card-body  p-4">
                      <div className="media">
                        <span className="me-3">
                          <MdDoNotDisturbOnTotalSilence />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">
                            My Total Bonus (After Deduction)
                          </p>
                          <h4 className="text-white">
                            {" "}
                            {(
                              userIncome?.growth_claim_bonus_USD / 1e18 +
                              growthLevel / 1e18 +
                              Number(ContdashInfo?.levelBonusUsd?._hex) / 1e18 +
                              (userIncome?.staking_bonus_USD / 1e18 +
                                stakeLevel / 1e18)
                            )?.toFixed(5)}{" "}
                            USDT
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#43477f" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <FiBox />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">
                            {" "}
                            Total Farming Amount in usdt
                          </p>
                          <h4 className="mb-0 text-white">
                            $ {totalFarmStake ? totalFarmStake?.toFixed(3) : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#6d315e" }}
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <FiBox />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 text-white">
                            {" "}
                            Total Return Amount in usdt
                          </p>
                          <h4 className="mb-0 text-white">
                            ${" "}
                            {
                              farminTotalamt === undefined || isNaN(farminTotalamt)
                                ? "0.00"
                                : Number(farminTotalamt).toFixed(3)
                            }

                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card"
                    style={{ background: "#9345cc" }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <h5 className="text-white">My Buy Back  </h5>
                          <h4 className="text-white">
                            $ {buybackWithData?.totalStakingUsdt}

                          </h4>
                          <h6 className="text-white">
                            Withdrawn | Available Bonus
                          </h6>

                          <p>
                            $ {buybackWithData?.withdrawalAmt}{" "}
                            |$ {buybackWithData?.walletBalance}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="card bg-secondary ">
                    <div className=" text-center">
                      <div className="toatal-email">
                        <h5>Current Price</h5>
                        <h4 className="text-white">
                          1 USD ~ {1 / currentPrice} {userToken?.name}
                        </h4>

                        <h4 className="text-warning">
                          1 {userToken?.name} ~ $ {currentPrice}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="row">
                <div className="col-xl-12 col-sm-6">
                  <div className="card h-auto">
                    <div className="card-body px-0 pt-1">
                      <Tab.Container defaultActiveKey="Navbuy">
                        <div className="">
                          <div className="buy-sell">
                            <Nav
                              className="nav nav-tabs"
                              eventKey="nav-tab2"
                              role="tablist"
                            >
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navbuy"
                                type="button"
                              >
                                Restake
                              </Nav.Link>
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navsell"
                                type="button"
                              >
                                Reward
                              </Nav.Link>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="Navbuy">
                              <Tab.Container defaultActiveKey="Navbuymarket">
                                <Tab.Content id="nav-tabContent1">
                                  <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                  <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                </Tab.Content>
                                <div className="sell-element">
                                  <div className="row">
                                    <div className=" text-end pt-2 text-success mb-2">
                                      {Number(tokenBalance)?.toFixed(2)} mBTYC
                                      <span className="text-primary">
                                        {" "}
                                        Token Balnce{" "}
                                      </span>{" "}
                                    </div>

                                    <div className="justify-content-center btn-register">
                                      <h5 className="card-title pt-1">
                                        Select Your Package
                                      </h5>
                                      <hr />
                                      <div className="row justify-content-center btn-register">
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                              setValue(25);
                                            }}
                                          >
                                            $ 25
                                          </button>
                                        </div>
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                              setValue(50);
                                            }}
                                          >
                                            $ 50
                                          </button>
                                        </div>
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              setValue(100);
                                            }}
                                          >
                                            $ 100
                                          </button>
                                        </div>
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              setValue(250);
                                            }}
                                          >
                                            $ 250
                                          </button>
                                        </div>
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-info"
                                            onClick={() => {
                                              setValue(500);
                                            }}
                                          >
                                            $ 500
                                          </button>
                                        </div>
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setValue(1000);
                                            }}
                                          >
                                            $ 1000
                                          </button>
                                        </div>
                                        <div className=" col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-warning"
                                            onClick={() => {
                                              setValue(5000);
                                            }}
                                          >
                                            $ 5000
                                          </button>
                                        </div>
                                        <div className=" col-4   mb-3">
                                          <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => {
                                              setValue(10000);
                                            }}
                                          >
                                            $ 10000
                                          </button>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <div className="  pt-1  mb-1">
                                          {" "}
                                          Calculate:
                                          <span className=" ps-1 text-success">
                                            {value} USD ~{" "}
                                            {value * (1 / currentPrice)} mBTYC
                                          </span>
                                        </div>
                                        <div className="  pt-1  mb-1">
                                          {" "}
                                          Capping-X:
                                          <span className=" ps-1 text-success">
                                            {packageDetails?.capping_X}
                                          </span>
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="d-flex justify-content-between">
                                        <div className="  pt-1  mb-1">
                                          {" "}
                                          Capping-USDT:
                                          <span className=" ps-1 text-success">
                                            $ {packageDetails?.capping_usdt}
                                          </span>
                                        </div>
                                        <div className="  pt-1  mb-1">
                                          {" "}
                                          ROI % Per Month:
                                          <span className=" ps-1 text-success">
                                            {
                                              packageDetails?.roi_percent_per_month
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="d-flex justify-content-between">
                                        <div className="  pt-1  mb-1">
                                          {" "}
                                          Total Month:
                                          <span className=" ps-1 text-success">
                                            20 Month (600 Days)
                                          </span>
                                        </div>
                                        <div className="  pt-1  mb-1">
                                          {" "}
                                          Total Return %:
                                          <span className=" ps-1 text-success">

                                            {
                                              packageDetails?.total_return_percent
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  </div>

                                  <div className="text-center mt-3">
                                    {regloading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled"        // Adjust the opacity here
                                      >
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                         
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        // onClick={() => Buy()}
                                        className="btn btn-primary w-75"
                                        style={{ opacity: 0.1 }}
                                      >
                                        BUY
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Navsell">
                              <Tab.Container defaultActiveKey="Navsellmarket">
                                <div className="sell-element">
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      Amount (USDT)
                                    </label>

                                    <div className="input-group mt-2 mb-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={userInfo?.rewardUSDT}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    {btnLoader ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        className="btn btn-primary w-75"
                                        onClick={() =>
                                          claimRewardAmt(walletAddress)
                                        }
                                      >
                                        Claim Reward
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6">
                  <div className="card h-auto">
                    <div className="card-body px-0 pt-1">
                      <Tab.Container defaultActiveKey="Navbuy">
                        <div className="">
                          <div className="buy-sell">
                            <Nav
                              className="nav nav-tabs"
                              eventKey="nav-tab2"
                              role="tablist"
                            >
                              <Nav.Link
                                as="button"
                                className="nav-link h6"
                                eventKey="Navbuy"
                                type="button"
                              >
                                Available Balance
                              </Nav.Link>
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navsell"
                                type="button"
                              >
                                Unstake Token
                              </Nav.Link>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="Navbuy">
                              <Tab.Container defaultActiveKey="Navbuymarket">
                                <Tab.Content id="nav-tabContent1">
                                  <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                  <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                </Tab.Content>
                                <div className="sell-element">
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      Claim Rewards
                                    </label>
                                    <div className="form-label blance">
                                      {/* <span>BALANCE:</span>
                                  <p>$3,123.9</p> */}
                                    </div>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={
                                          growthLevel / 1e18 + stakeLevel / 1e18
                                        }
                                      />
                                      <span className="input-group-text">
                                        USDT
                                      </span>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-info">
                                        {growthLevel / 1e18}
                                      </span>
                                      +{" "}
                                      <span className="text-info">
                                        {stakeLevel / 1e18}
                                      </span>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-warning">
                                        Growth Level Bonus
                                      </span>
                                      +{" "}
                                      <span className="text-warning">
                                        Staked Bonus
                                      </span>
                                    </div>
                                  </div>
                                  <div className="text-center pt-3">
                                    {btnLoading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm pr-1"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        className="btn btn-primary w-75"
                                        onClick={() => {
                                          if ((stakeLevel / 1e18 + growthLevel / 1e18) >= 20) {

                                            if (claimReward / 1e18 > 0) {
                                              if (
                                                Number(
                                                  ContdashInfo?.cappingUsed?._hex
                                                ) /
                                                1e18 <
                                                Number(
                                                  ContdashInfo?.cappingLimit?._hex
                                                ) /
                                                1e18
                                              ) {
                                                setBasicModal(true);
                                              } else {
                                                toast.error(
                                                  "Your Income Limit Expire:Please Restake ! "
                                                );
                                              }
                                            } else {
                                              toast.error(
                                                "amount Must be greater than 0 !"
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              "Minimum Withdwals amount 20$!"
                                            );
                                          }


                                        }}
                                      >
                                        Claim Reward
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Navsell">
                              <Tab.Container defaultActiveKey="Navsellmarket">
                                <div className="sell-element">
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      Withdraw Staked Token
                                    </label>
                                    <div className="form-label blance"></div>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={wthdrawStakingToken / 1e18}
                                      />
                                      <span className="input-group-text">
                                        USDT
                                      </span>
                                    </div>
                                  </div>
                                  <div className="text-center pt-3">
                                    {btnLoading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        className="btn btn-primary w-75"
                                        onClick={() => {
                                          if (wthdrawStakingToken / 1e18 > 0) {
                                            claimStakeToken(
                                              walletAddress,
                                              setRefresh,
                                              setBtnLoading,
                                              setWithdrawStakeToken
                                            );
                                          } else {
                                            toast.error(
                                              "amount Must be greater than 0 !"
                                            );
                                          }
                                        }}
                                      >
                                        Withdraw Staked Token
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6">
                  <div className="card">
                    <div className="card-header py-2">
                      <h2 className="heading">Referral Id </h2>
                    </div>
                    <div className="input-group mb-3 p-2 mt-2">
                      <input
                        id="copy"
                        type="text"
                        className="form-control"
                        value={`https://bigtycoon.io/register?user_id=${randomId}`}
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => copy_refral()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6">
                  <div className="card">
                    <div className="card-header py-2">
                      <h2 className="heading">Wallet Address </h2>
                    </div>
                    <div className="input-group mb-3 p-2 mt-2">
                      <input
                        id="copywallet"
                        type="text"
                        className="form-control"
                        value={walletAddress}
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => copy_wallet()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6">
                  <div className="card h-auto">
                    <div className="card-body px-0 pt-1">
                      <Tab.Container defaultActiveKey="Navbuy">
                        <div className="">
                          <div className="buy-sell">
                            <Nav
                              className="nav nav-tabs"
                              eventKey="nav-tab2"
                              role="tablist"
                            >
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navbuy"
                                type="button"
                              >
                                Crypto Farming
                              </Nav.Link>
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navsell"
                                type="button"
                              >
                                Claim Farming
                              </Nav.Link>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="Navbuy">
                              <Tab.Container defaultActiveKey="Navbuymarket">
                                <Tab.Content id="nav-tabContent1">
                                  <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                  <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                </Tab.Content>
                                <div className="sell-element">
                                  <div className="row">
                                    <div className="text-center">
                                      <div className="pt-1 text-success mb-1">
                                        1 USDT ~{" "}
                                        {btycRate ? btycRate?.toFixed(2) : 0}{" "}
                                        BTYC
                                        <span className="text-primary">
                                          {" "}
                                          Current Price{" "}
                                        </span>{" "}
                                      </div>
                                      <div className="  pt-1 text-success mb-1">
                                        {tokenBtycBalance} BTYC
                                        <span className="text-primary">
                                          {" "}
                                          Token Balnce{" "}
                                        </span>{" "}
                                      </div>
                                    </div>

                                    <div className="justify-content-center btn-register">
                                      <h6 className="card-title pt-1">
                                        Amount
                                      </h6>
                                      <div className="input-group mb-3 p-2 mt-2">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="BTYC Amount"
                                          value={farmingTokenAmt}
                                          onChange={(e) =>
                                            changePriceFarming(
                                              e.target.value,
                                              1
                                            )
                                          }
                                        />
                                        <div className="d-flex justify-content-center align-items-center">
                                          {" "}
                                          <MdSwapHorizontalCircle
                                            size={25}
                                            color="#000"
                                          />
                                        </div>

                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="USDT Amont"
                                          value={farmingDollarAmt}
                                          onChange={(e) =>
                                            changePriceFarming(
                                              e.target.value,
                                              2
                                            )
                                          }
                                        />
                                      </div>

                                      <h6 className="card-title pb-2">
                                        Farming Tenure
                                      </h6>
                                      <div className="basic-form">
                                        <div className="form-group mb-0">
                                          <label className="radio-inline me-3">
                                            <input
                                              id="duration1"
                                              type="radio"
                                              name="optradio"
                                              value={duration}
                                              onChange={(e) => {
                                                setDuration(180);
                                              }}
                                            />{" "}
                                            180 Days
                                          </label>
                                          <label className="radio-inline me-3">
                                            <input
                                              type="radio"
                                              name="optradio"
                                              value={duration}
                                              onChange={(e) => {
                                                setDuration(360);
                                              }}
                                            />{" "}
                                            360 Days
                                          </label>
                                          <label className="radio-inline me-3 ">
                                            <input
                                              type="radio"
                                              name="optradio"
                                              value={duration}
                                              onChange={(e) => {
                                                setDuration(720);
                                              }}
                                            />
                                            <span className="ms-1">
                                              720 Days
                                            </span>
                                          </label>
                                          <label className="radio-inline me-3 ">
                                            <input
                                              type="radio"
                                              name="optradio"
                                              value={duration}
                                              onChange={(e) => {
                                                setDuration(1080);
                                              }}
                                            />
                                            <span className="ms-1">
                                              1080 Days
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="  pt-1 fw-bolder text-center  mb-1">
                                        {" "}
                                        Farming Return In USDT($) :
                                        <span className=" ps-1 text-success">
                                          {farmingDetails?.return_dollar *
                                            farmingDollarAmt}
                                        </span>
                                      </div>

                                      <div className="  pt-1 fw-bolder text-center  mb-1">
                                        {" "}
                                        Disbursement in (mBTYC) :
                                        <span className=" ps-1 text-success">
                                          1 % Daily next 100 days
                                        </span>
                                      </div>
                                      <hr />
                                    </div>
                                  </div>

                                  <div className="text-center mt-3">
                                    {farmloading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        onClick={() => FarmingBtyc()}
                                        className="btn btn-primary w-75"
                                      >
                                        Farming Now
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Navsell">
                              <Tab.Container defaultActiveKey="Navsellmarket">
                                <div className="sell-element">
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      Amount (mBTYC)
                                    </label>

                                    <div className="input-group mt-2 mb-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={mBtycFarmReward}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    {btnLoader ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        className="btn btn-primary w-75"
                                        onClick={() =>
                                          claimFarmReward(walletAddress)
                                        }
                                      >
                                        Claim Reward
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-sm-6">
                  <div className="card h-auto">
                    <div className="card-body px-0 pt-1">
                      <Tab.Container defaultActiveKey="Navbuy">
                        <div className="">
                          <div className="buy-sell">
                            <Nav
                              className="nav nav-tabs"
                              eventKey="nav-tab2"
                              role="tablist"
                            >
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navbuy"
                                type="button"
                              >
                                BTYC Buy Back
                              </Nav.Link>
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navsell"
                                type="button"
                              >
                                WithDrwal USDT
                              </Nav.Link>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="Navbuy">
                              <Tab.Container defaultActiveKey="Navbuymarket">
                                <Tab.Content id="nav-tabContent1">
                                  <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                  <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                </Tab.Content>
                                <div className="sell-element">
                                  <div className="row">
                                    <div className="text-center">
                                      <div className="pt-1 text-success mb-1">
                                        1 USDT ~{" "}
                                        {currPriceApi ? currPriceApi : 0}{" "}
                                        BTYC
                                        <span className="text-primary">
                                          {" "}
                                          Current Price{" "}
                                        </span>{" "}
                                      </div>
                                      <div className="  pt-1 text-success mb-1">
                                        {tokenBtycBalance} BTYC
                                        <span className="text-primary">
                                          {" "}
                                          Token Balnce{" "}
                                        </span>{" "}
                                      </div>
                                    </div>

                                    <div className="justify-content-center btn-register">
                                      <h6 className="card-title pt-1">
                                        Amount
                                      </h6>

                                      <div className="input-group mb-3 p-2 mt-2">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="BTYC Amount"
                                          value={btycSellAmount}
                                          onChange={(e) => changePriceBtycEx(e.target.value, 1)}

                                        />
                                        <div className="d-flex justify-content-center align-items-center">
                                          {" "}
                                          <MdSwapHorizontalCircle
                                            size={25}
                                            color="#000"
                                          />
                                        </div>

                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="USDT Amont"
                                          value={btycSellAmountDollar}
                                          onChange={(e) => changePriceBtycEx(e.target.value, 2)}

                                        />

                                      </div>




                                      <hr />
                                    </div>
                                  </div>

                                  <div className="text-center mt-3">
                                    {buBackLoading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        onClick={() => BuyBackBtycLock()}
                                        className="btn btn-primary w-75"
                                      >
                                        Submit
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Navsell">
                              <Tab.Container defaultActiveKey="Navsellmarket">
                                <div className="sell-element">
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      Amount ($)
                                    </label>

                                    <div className="input-group mt-2 mb-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={buybackWithData?.walletBalance}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    {btnWithLoader ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        className="btn btn-primary w-75"
                                        onClick={() =>
                                          withDwalBuyBackAmount()
                                        }
                                      >
                                        Withdraw
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal --> */}
            <Modal className="fade" show={basicModal}>
              <Modal.Header>
                <Modal.Title>Claim Income</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setBasicModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <div className="fs-6 fw-bold">
                    <span className="text-warning">
                      <span className="text-dark ">Total :</span>{" "}
                      {growthLevel / 1e18 + stakeLevel / 1e18} USDT
                    </span>
                  </div>
                  <div className="fs-6 fw-bold">
                    <span className="text-info">
                      <span className="text-dark ">Total Token Received :</span>{" "}
                      {(growthLevel / 1e18 + stakeLevel / 1e18) *
                        (1 / currentPrice)}{" "}
                      mBTYC
                    </span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setBasicModal(false)}
                  variant="danger light"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    claimRewards(
                      walletAddress,
                      setRefresh,
                      setBtnLoading,
                      setClaimReward
                    );
                    setBasicModal(false);
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};
export default Home;
